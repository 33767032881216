<template>
  <div class="msg_bg_view" :style="`${isMobile ? '' : 'max-width:375px;margin: 0 auto'}`">
    <div v-if="isMobile">{{ messageList.length ? `消息发送中${taskIndex + 1}/${messageList.length}` : '暂无消息' }}</div>
    <div v-else>页面升级中，暂不可使用</div>
  </div>
</template>

<script>
import * as ww from '@wecom/jssdk';
import API from '@/common/axios';
export default {
  data() {
    return {
      appId: "ww8c91222f5023cad4",
      agentId: "1000047",
      userId: "",
      messageList: [],
      taskIndex: 0,
      isMobile: /Mobi|Android|iPhone|iPad|iPod|Windows Phone|BlackBerry|SymbianOS|Opera Mini|IEMobile/.test(navigator.userAgent)
    };
  },
  mounted() {
    if (!this.isMobile) {
      return;
    }
    if (!this.$route.query.code) {
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appId}&agentid=${this.agentId}&redirect_uri=${encodeURIComponent(window.location.href)}&response_type=code&scope=snsapi_base&state=#wechat_redirect`;
    } else {
      this.init();
    }
  },
  methods: {
    // 初始化获取授权信息
    init() {
      API({
        url: "/wechat/getUserByCode.do",
        data: {
          url: window.location.href,
          code: this.$route.query.code,
        }
      }).then(res => {
        if (res.success != 1) {
          this.qwError();
        } else {
          this.userId = res.data.userId;
          this.initQWAuth(res.data);
        }
      }).catch(_ => {
        this.qwError();
      });
    },
    // 企微授权
    initQWAuth(data) {
      ww.register({
        corpId: this.appId,
        agentId: this.agentId,
        jsApiList: ['sendChatMessage', 'getCurExternalContact'],
        getAgentConfigSignature: function () {
          return { ...data, nonceStr: data.noncestr };
        },
        onAgentConfigSuccess: _ => {
          this.getCurExternalContact();
        },
        onAgentConfigFail: _ => {
          this.qwError();
        }
      });
    },
    // 获取外部联系人ID
    getCurExternalContact() {
      ww.getCurExternalContact({
        success: res => {
          this.externalUserId = res.userId;
          this.getMessageList();
        },
        fail: () => {
          this.sendStatus({
            errMessage: '获取外部联系人ID失败',
            status: 10
          }, true);
        },
      });
    },
    // 获取消息列表
    async getMessageList() {
      API({
        url: "/sendingMessage/queryLastMsg.do",
        data: {
          userId: this.userId,
          externalUserId: this.externalUserId
        }
      }).then(res => {
        let list = res.data;
        if (res.success == 1 && list && list.length) {
          this.messageList = list;
          this.startTask();
        } else {
          this.messageListError();
        }
      }).catch(_ => {
        this.messageListError();
      });
    },
    // 关闭窗口
    closeWindow() {
      ww.closeWindow();
    },
    // 开始任务
    startTask() {
      let params = { msgtype: "", enterChat: false };
      let obj = this.messageList[this.taskIndex];
      if (obj.type == 'text') {
        params.msgtype = 'text';
        params.text = { content: obj.content };
      } else if (obj.type == 'weapp') {
        params.msgtype = 'miniprogram';
        params.miniprogram = {
          appid: obj.appId,
          title: obj.title,
          page: obj.page,
          imgUrl: obj.fileURL
        };
      } else if (obj.type == 'image') {
        params.msgtype = 'image';
        params.image = { mediaid: obj.mediaId };
      } else if (obj.type == 'video') {
        params.msgtype = 'video';
        params.video = { mediaid: obj.mediaId };
      } else if (obj.type == 'link') {
        params.msgtype = 'news';
        params.news = {
          link: obj.link,
          title: obj.title,
          desc: obj.desc,
          imgUrl: obj.fileURL
        };
      }
      let needClose = (this.taskIndex == this.messageList.length - 1);
      ww.sendChatMessage({
        ...params,
        success: () => {
          this.sendStatus({
            errMessage: '消息发送成功',
            uuid: obj.uuid,
            status: 1
          }, needClose);
        },
        fail: () => {
          this.sendStatus({
            errMessage: '消息发送失败',
            uuid: obj.uuid,
            status: 10
          }, needClose);
        },
        cancel: () => {
          this.sendStatus({
            errMessage: '消息取消发送',
            uuid: obj.uuid,
            status: 10
          }, needClose);
        },
        complete: () => {
          this.nextTask();
        }
      });
    },
    // 开启下一个任务
    nextTask() {
      if (this.taskIndex < this.messageList.length - 1) {
        this.taskIndex += 1;
        this.startTask();
      }
    },
    // 状态上传
    sendStatus(params, needClose) {
      API({
        url: "/sendingMessage/changeStatus.do",
        data: {
          userId: this.userId || '',
          ...params
        }
      }).finally(_ => {
        if (needClose) {
          this.closeWindow();
        }
      });
    },
    // 企微授权失败
    qwError() {
      this.sendStatus({
        errMessage: '企微授权失败',
        status: 10
      }, true);
    },
    // 消息获取失败
    messageListError() {
      this.sendStatus({
        errMessage: '获取消息列表失败',
        status: 10
      }, true);
    },
  }
};
</script>

<style lang="scss" scoped>
.msg_bg_view {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 20px;
  color: #409eff;
}
</style>